@tailwind base;
@tailwind components;
@tailwind utilities;

#nprogress .bar {
  background: #F59E0B !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #F59E0B, 0 0 5px #F59E0B !important;
}

#nprogress .spinner-icon {
  border-top-color: #F59E0B !important;
  border-left-color: #F59E0B !important;
}
